<template>
  <el-container class="h-100p" style="gap: 10px">
    <el-aside width="300px">
      <basic-container isCard class="h-100p">
        <avue-tree :option="treeOption" :data="treeData" @node-click="nodeClick" />
      </basic-container>
      <!-- <el-scrollbar>
      </el-scrollbar> -->
    </el-aside>
    <el-main>
      <basic-container class="h-100p">
        <avue-crud
          :option="option"
          :search.sync="search"
          :table-loading="loading"
          :data="data"
          ref="crud"
          v-model="form"
          :permission="permissionList"
          @row-del="rowDel"
          @row-update="rowUpdate"
          @row-save="rowSave"
          :before-open="beforeOpen"
          :page.sync="page"
          @search-change="searchChange"
          @search-reset="searchReset"
          @selection-change="selectionChange"
          @current-change="currentChange"
          @size-change="sizeChange"
          @refresh-change="refreshChange"
          @on-load="onLoad"
          @keyup.enter.native="enterEvent"
        >
          <template slot="menuLeft">
            <el-button
              type="danger"
              size="small"
              plain
              icon="el-icon-delete"
              v-if="permission.user_delete"
              @click="handleDelete"
              >删 除
            </el-button>
            <el-button
              type="info"
              size="small"
              plain
              v-if="permission.user_role"
              icon="el-icon-user"
              @click="handleGrant"
              >角色配置
            </el-button>
            <el-button
              type="info"
              size="small"
              plain
              v-if="permission.user_batch_permission"
              icon="el-icon-goods"
              @click="openDoorsDis"
              >批量开通门禁
            </el-button>
            <el-button type="info" size="small" icon="el-icon-s-tools" @click="excelFaceBoxDia"
              >导入人脸图像
            </el-button>
            <el-button
              type="info"
              size="small"
              plain
              v-if="permission.user_reset"
              icon="el-icon-refresh"
              @click="handleReset"
              >密码重置
            </el-button>
            <el-button
              type="info"
              size="small"
              plain
              v-if="userInfo.role_name.includes('admin')"
              icon="el-icon-setting"
              @click="handlePlatform"
              >平台配置
            </el-button>
            <el-button
              type="success"
              size="small"
              plain
              v-if="userInfo.role_name.includes('admin')"
              icon="el-icon-upload2"
              @click="handleImport"
              >导入
            </el-button>
            <el-button
              type="warning"
              size="small"
              plain
              v-if="userInfo.role_name.includes('admin')"
              icon="el-icon-download"
              @click="handleExport"
              >导出
            </el-button>
          </template>
          <template slot-scope="{ row }" slot="tenantName">
            <el-tag>{{ row.tenantName }}</el-tag>
          </template>
          <template slot-scope="{ row }" slot="roleName">
            <el-tag>{{ row.roleName }}</el-tag>
          </template>
          <template slot-scope="{ row }" slot="deptName">
            <el-tag>{{ row.deptName }}</el-tag>
          </template>
          <template slot-scope="{ row }" slot="userTypeName">
            <el-tag>{{ row.userTypeName }}</el-tag>
          </template>
        </avue-crud>
        <el-dialog title="用户角色配置" append-to-body :visible.sync="roleBox" width="345px">
          <el-tree
            :data="roleGrantList"
            show-checkbox
            check-strictly
            default-expand-all
            node-key="id"
            ref="treeRole"
            :default-checked-keys="roleTreeObj"
            :props="props"
          >
          </el-tree>

          <span slot="footer" class="dialog-footer">
            <el-button @click="roleBox = false">取 消</el-button>
            <el-button type="primary" @click="submitRole">确 定</el-button>
          </span>
        </el-dialog>
        <el-dialog title="用户数据导入" append-to-body :visible.sync="excelBox" width="555px">
          <avue-form :option="excelOption" v-model="excelForm" :upload-after="uploadAfter">
            <template slot="excelTemplate">
              <el-button type="primary" @click="handleTemplate">
                点击下载<i class="el-icon-download el-icon--right"></i>
              </el-button>
            </template>
          </avue-form>
        </el-dialog>
        <el-dialog title="用户平台配置" append-to-body :visible.sync="platformBox">
          <avue-crud
            :option="platformOption"
            :table-loading="platformLoading"
            :data="platformData"
            ref="platformCrud"
            v-model="platformForm"
            :before-open="platformBeforeOpen"
            :page.sync="platformPage"
            :permission="platformPermissionList"
            @row-update="platformRowUpdate"
            @search-change="platformSearchChange"
            @search-reset="platformSearchReset"
            @selection-change="platformSelectionChange"
            @current-change="platformCurrentChange"
            @size-change="platformSizeChange"
            @refresh-change="platformRefreshChange"
            @on-load="platformOnLoad"
          >
            <template slot-scope="{ row }" slot="tenantName">
              <el-tag>{{ row.tenantName }}</el-tag>
            </template>
            <template slot-scope="{ row }" slot="userTypeName">
              <el-tag>{{ row.userTypeName }}</el-tag>
            </template>
          </avue-crud>
        </el-dialog>
        <el-dialog title="资源组列表" append-to-body :visible.sync="hk_qgk_doorsDis" width="555px">
          <el-radio-group v-model="hk_qgk_doors_ids" class="pad-l-20">
            <el-row v-for="item of hk_qgk_doors" class="mar-b-15">
              <el-radio :label="item.dictValue" :key="item.dictValue">{{ item.remark }}</el-radio>
            </el-row>
          </el-radio-group>
          <el-row>
            <el-button size="small" type="" class="pull-right mar-t-10" @click="hk_qgk_doorsDis = false"
              >取消</el-button
            >
            <el-button size="small" class="pull-right mar-t-10 mar-r-10" type="primary" @click="addQGKHKUserAndAuth"
              >确定</el-button
            >
          </el-row>
        </el-dialog>
        <el-dialog title="导入人脸图像" append-to-body :visible.sync="excelFaceBox" width="555px">
          <div class="pad-t-10">
            <avue-form
              :option="excelFaceOption"
              v-model="excelFaceForm"
              :upload-after="uploadFaceAfter"
              :upload-before="uploadFaceBefore"
            >
            </avue-form>
          </div>
        </el-dialog>
      </basic-container>
    </el-main>
  </el-container>
</template>

<script>
import {
  getList,
  getUser,
  getUserPlatform,
  remove,
  update,
  updatePlatform,
  add,
  grant,
  resetPassword,
  addQGKHKUserAndAuth,
  exportUserData,
} from "@/api/system/user"
import { getList as getParamList } from "@/api/system/param.js"
import { getPicListByIds } from "@/api/busiMode/metaCommon"
import { getDeptTree, getDeptLazyTree } from "@/api/system/dept"
import { getRoleTree } from "@/api/system/role"
import { getPostList } from "@/api/system/post"
import { mapGetters } from "vuex"
import website from "@/config/website"
import { getToken } from "@/util/auth"
import { getDictionary } from "@/api/system/dict"
import { downFileByResult } from "@/api/file"
import { re } from "mathjs"
import * as dictService from "@/api/system/dictbiz"

export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"))
      } else {
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"))
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"))
      } else {
        callback()
      }
    }
    return {
      initPassword: "",
      form: {},
      search: {},
      roleBox: false,
      excelBox: false,
      platformBox: false,
      initFlag: true,
      selectionList: [],
      excelFaceBox: false,
      query: {},
      loading: true,
      platformLoading: false,
      excelFaceForm: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      platformPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      init: {
        roleTree: [],
        deptTree: [],
      },
      props: {
        label: "title",
        value: "key",
      },
      roleGrantList: [],
      roleTreeObj: [],
      treeDeptId: "",
      treeDepObj: {},
      treeData: [],
      treeOption: {
        nodeKey: "id",
        lazy: true,
        treeLoad: function (node, resolve) {
          const parentId = node.level === 0 ? 0 : node.data.id
          console.log(parentId)
          getDeptLazyTree(parentId).then((res) => {
            resolve(
              res.data.data.map((item) => {
                return {
                  ...item,
                  leaf: !item.hasChildren,
                }
              })
            )
          })
        },
        addBtn: false,
        menu: false,
        size: "small",
        props: {
          labelText: "标题",
          label: "title",
          value: "value",
          children: "children",
        },
      },
      excelFaceOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "资源组列表",
            prop: "doorsDictValue",
            type: "radio",
            props: {
              label: "remark",
              value: "dictValue",
            },
            span: 24,
            dicData: [],
            change: ({ value, column }) => {
              this.excelFaceOption.column[1].action =
                "/api/park-company/company/importUsersFace?deptId=" + this.treeDeptId + "&doorsDictValue=" + value
              this.$forceUpdate()
            },
          },
          {
            label: "模板上传",
            prop: "excelFile",
            type: "upload",
            drag: true,
            loadText: "模板上传中，请稍等",
            span: 24,
            accept: "zip/*",
            propsHttp: {
              res: "data",
            },
            tip: "支持导入ZIP文件，建议文件中包含照片的数量在1000张以内；照片命名规则为姓名_手机号.jpg，需露出眉毛和眼睛；单张照片文件大小20 KB~10MB。",
            action: "/api/park-company/company/importUsersFace",
          },
        ],
      },
      option: {
        height: "auto",
        // calcHeight: 80,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        selection: true,
        viewBtn: true,
        //dialogType: 'drawer',
        dialogClickModal: false,
        column: [
          {
            label: "登录账号",
            prop: "account",
            search: true,
            display: false,
          },
          {
            label: "所属租户",
            prop: "tenantName",
            slot: true,
            display: false,
          },
          {
            label: "用户姓名",
            prop: "realName",
            search: true,
            display: false,
          },
          {
            label: "所属角色",
            prop: "roleName",
            slot: true,
            display: false,
          },
          {
            label: "所属部门",
            prop: "deptName",
            slot: true,
            display: false,
          },
          {
            label: "用户平台",
            prop: "userTypeName",
            slot: true,
            display: false,
          },
          {
            width: 100,
            label: "人脸识别开通状态",
            prop: "faceOcrStatus",
            viewDisplay: false,
            addDisplay: false,
            editDisplay: false,
            type: "select",
            dicUrl: dictService.getUrl("faceOcrStatus"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "用户平台",
            type: "select",
            dicUrl: "/api/jida-system/dict/dictionary?code=user_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            search: true,
            hide: true,
            display: false,
            prop: "userType",
            rules: [
              {
                required: true,
                message: "请选择用户平台",
                trigger: "blur",
              },
            ],
          },
        ],
        group: [
          {
            label: "基础信息",
            prop: "baseInfo",
            icon: "el-icon-user-solid",
            column: [
              {
                label: "所属租户",
                prop: "tenantId",
                type: "tree",
                dicUrl: "/api/jida-system/tenant/select",
                props: {
                  label: "tenantName",
                  value: "tenantId",
                },
                hide: !website.tenantMode,
                addDisplay: website.tenantMode,
                editDisplay: website.tenantMode,
                viewDisplay: website.tenantMode,
                rules: [
                  {
                    required: true,
                    message: "请输入所属租户",
                    trigger: "click",
                  },
                ],
                span: 24,
              },
              {
                label: "登录账号",
                prop: "account",
                rules: [
                  {
                    required: true,
                    message: "请输入登录账号",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "用户平台",
                type: "select",
                dicUrl: "/api/jida-system/dict/dictionary?code=user_type",
                props: {
                  label: "dictValue",
                  value: "dictKey",
                },
                dataType: "number",
                slot: true,
                prop: "userType",
                rules: [
                  {
                    required: true,
                    message: "请选择用户平台",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "密码",
                prop: "password",
                hide: true,
                editDisplay: false,
                viewDisplay: false,
                rules: [{ required: true, validator: validatePass, trigger: "blur" }],
              },
              {
                label: "确认密码",
                prop: "password2",
                hide: true,
                editDisplay: false,
                viewDisplay: false,
                rules: [{ required: true, validator: validatePass2, trigger: "blur" }],
              },
            ],
          },
          {
            label: "职责信息",
            prop: "dutyInfo",
            icon: "el-icon-s-custom",
            column: [
              {
                label: "用户编号",
                prop: "code",
              },
              {
                label: "所属角色",
                prop: "roleId",
                multiple: true,
                type: "tree",
                dicData: [],
                props: {
                  label: "title",
                },
                checkStrictly: true,
                slot: true,
                rules: [
                  {
                    required: true,
                    message: "请选择所属角色",
                    trigger: "click",
                  },
                ],
              },
              {
                label: "所属部门",
                prop: "deptId",
                type: "tree",
                multiple: true,
                dicData: [],
                props: {
                  label: "title",
                },
                checkStrictly: true,
                slot: true,
                rules: [
                  {
                    required: true,
                    message: "请选择所属部门",
                    trigger: "click",
                  },
                ],
              },
              {
                label: "所属岗位",
                prop: "postId",
                type: "tree",
                multiple: true,
                dicData: [],
                props: {
                  label: "postName",
                  value: "id",
                },
                rules: [
                  {
                    required: true,
                    message: "请选择所属岗位",
                    trigger: "click",
                  },
                ],
              },
            ],
          },
          {
            label: "详细信息",
            prop: "detailInfo",
            icon: "el-icon-s-order",
            column: [
              {
                label: "用户昵称",
                prop: "name",
                hide: true,
                rules: [
                  {
                    required: true,
                    message: "请输入用户昵称",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "用户姓名",
                prop: "realName",
                rules: [
                  {
                    required: true,
                    message: "请输入用户姓名",
                    trigger: "blur",
                  },
                  {
                    min: 2,
                    max: 20,
                    message: "姓名长度在2到20个字符",
                  },
                ],
              },
              {
                label: "手机号码",
                prop: "phone",
                overHidden: true,
                rules: [
                  {
                    required: true,
                    message: "请输入手机号码",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "电子邮箱",
                prop: "email",
                hide: true,
                overHidden: true,
              },
              {
                label: "用户性别",
                prop: "sex",
                type: "select",
                dicData: [
                  {
                    label: "男",
                    value: 1,
                  },
                  {
                    label: "女",
                    value: 2,
                  },
                  {
                    label: "未知",
                    value: 3,
                  },
                ],
                rules: [
                  {
                    required: true,
                    message: "请选用户性别",
                    trigger: "blur",
                  },
                ],

                hide: true,
              },
              {
                label: "用户生日",
                type: "date",
                prop: "birthday",
                format: "yyyy-MM-dd hh:mm:ss",
                valueFormat: "yyyy-MM-dd hh:mm:ss",
                hide: true,
              },
              {
                label: "头像",
                prop: "avatar",
                span: 24,
                component: "form-file",
                params: {
                  max: 1,
                  accept: "image/*",
                  multiple: false,
                },
                // type: "upload",
                // listType: "picture-card",
                // loadText: "附件上传中，请稍等",
                // limit: 1,
                // propsHttp: {
                //   res: "data",
                //   url: "link",
                //   name: "attachId",
                // },
                // tip: "只能上传一张jpg/png用户头像，且不超过500kb",
                // action: "/api/jida-resource/oss/endpoint/put-file-attach",
              },
              {
                label: "账号状态",
                prop: "statusName",
                hide: true,
                display: false,
              },
            ],
          },
        ],
      },
      data: [],
      platformQuery: {},
      platformSelectionList: [],
      platformData: [],
      platformForm: {},
      platformOption: {
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: false,
        index: true,
        selection: true,
        viewBtn: true,
        dialogClickModal: false,
        menuWidth: 120,
        editBtnText: "配置",
        column: [
          {
            label: "登录账号",
            prop: "account",
            search: true,
            display: false,
          },
          {
            label: "所属租户",
            prop: "tenantName",
            slot: true,
            display: false,
          },
          {
            label: "用户姓名",
            prop: "realName",
            search: true,
            display: false,
          },
          {
            label: "用户平台",
            prop: "userTypeName",
            slot: true,
            display: false,
          },

          {
            label: "用户平台",
            type: "select",
            dicUrl: "/api/jida-system/dict/dictionary?code=user_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            search: true,
            hide: true,
            display: false,
            prop: "userType",
            rules: [
              {
                required: true,
                message: "请选择用户平台",
                trigger: "blur",
              },
            ],
          },
          {
            label: "用户拓展",
            prop: "userExt",
            type: "textarea",
            minRows: 8,
            span: 24,
            overHidden: true,
            row: true,
            hide: true,
          },
        ],
      },
      excelForm: {},
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "模板上传",
            prop: "excelFile",
            type: "upload",
            drag: true,
            loadText: "模板上传中，请稍等",
            span: 24,
            propsHttp: {
              res: "data",
            },
            tip: "请上传 .xls,.xlsx 标准格式文件",
            action: "/api/jida-user/import-user",
          },
          {
            label: "数据覆盖",
            prop: "isCovered",
            type: "switch",
            align: "center",
            width: 80,
            dicData: [
              {
                label: "否",
                value: 0,
              },
              {
                label: "是",
                value: 1,
              },
            ],
            value: 0,
            slot: true,
            rules: [
              {
                required: true,
                message: "请选择是否覆盖",
                trigger: "blur",
              },
            ],
          },
          {
            label: "模板下载",
            prop: "excelTemplate",
            formslot: true,
            span: 24,
          },
        ],
      },
      hk_qgk_doorsDis: false,
      hk_qgk_doors: [],
      hk_qgk_doors_ids: [],
    }
  },
  watch: {
    "form.tenantId"() {
      if (this.form.tenantId !== "" && this.initFlag) {
        this.initData(this.form.tenantId)
      }
    },
    "excelForm.isCovered"() {
      if (this.excelForm.isCovered !== "") {
        const column = this.findObject(this.excelOption.column, "excelFile")
        column.action = `/api/jida-user/import-user?isCovered=${this.excelForm.isCovered}`
      }
    },
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.user_add, false),
        viewBtn: this.vaildData(this.permission.user_view, false),
        delBtn: this.vaildData(this.permission.user_delete, false),
        editBtn: this.vaildData(this.permission.user_edit, false),
      }
    },
    platformPermissionList() {
      return {
        addBtn: false,
        viewBtn: false,
        delBtn: false,
        editBtn: this.vaildData(this.permission.user_edit, false),
      }
    },
    ids() {
      let ids = []
      this.selectionList.forEach((ele) => {
        ids.push(ele.id)
      })
      return ids.join(",")
    },
  },
  mounted() {
    // 非租户模式默认加载管理组数据
    if (!website.tenantMode) {
      this.initData(website.tenantId)
    }
    getParamList(1, 10, {
      paramKey: "account.initPassword",
    }).then((res) => {
      console.log(res)
      if (res.data.data.records && res.data.data.records.length) {
        this.initPassword = res.data.data.records[0].paramValue
      }
    })
    this.getDictionary()
  },
  methods: {
    enterEvent() {
      this.page.currentPage = 1
      this.onLoad(this.page, this.search)
    },
    nodeClick(data) {
      this.treeDepObj = data
      this.treeDeptId = data.id
      this.page.currentPage = 1
      this.onLoad(this.page)
    },
    initData(tenantId) {
      getRoleTree(tenantId).then((res) => {
        const column = this.findObject(this.option.group, "roleId")
        column.dicData = res.data.data
      })
      getDeptTree(tenantId).then((res) => {
        const column = this.findObject(this.option.group, "deptId")
        column.dicData = res.data.data
      })
      getPostList(tenantId).then((res) => {
        const column = this.findObject(this.option.group, "postId")
        column.dicData = res.data.data
      })
    },
    submitRole() {
      const roleList = this.$refs.treeRole.getCheckedKeys().join(",")
      grant(this.ids, roleList).then(() => {
        this.roleBox = false
        this.$message({
          type: "success",
          message: "操作成功!",
        })
        this.onLoad(this.page)
      })
    },
    rowSave(row, done, loading) {
      if (row.deptId) {
        row.deptId = row.deptId.toString()
      }
      if (row.roleId) {
        row.roleId = row.roleId.toString()
      }
      if (row.postId) {
        row.postId = row.postId.toString()
      }
      add(row).then(
        () => {
          this.initFlag = false
          this.onLoad(this.page)
          this.$message({
            type: "success",
            message: "操作成功!",
          })
          done()
        },
        (error) => {
          window.console.log(error)
          loading()
        }
      )
    },
    uploadFaceAfter(res, done, loading, column) {
      this.excelFaceForm = {
        doorsDictValue: "",
      }
      delete this.excelFaceForm.excelFile
      this.excelFaceForm = JSON.parse(JSON.stringify(this.excelFaceForm))
      this.onLoad(this.page)
      this.excelFaceBox = false
      done()
    },
    uploadFaceBefore(res, done, loading, column) {
      if (!this.excelFaceForm["doorsDictValue"]) {
        this.$message({
          type: "error",
          message: "请选择资源组",
        })
        loading()
      } else {
        // if (res.type != "application/zip") {
        //   this.$message({
        //     type: "error",
        //     message: "文件格式必须是ZIP",
        //   });
        //   loading();
        // } else {
        // }
        done()
      }
    },
    rowUpdate(row, index, done, loading) {
      console.log(row)
      if (row.deptId) {
        row.deptId = row.deptId.toString()
      }
      if (row.roleId) {
        row.roleId = row.roleId.toString()
      }
      if (row.postId) {
        row.postId = row.postId.toString()
      }
      // return false;
      update(row).then(
        () => {
          this.initFlag = false
          this.onLoad(this.page)
          this.$message({
            type: "success",
            message: "操作成功!",
          })
          done()
        },
        (error) => {
          window.console.log(error)
          loading()
        }
      )
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: "success",
            message: "操作成功!",
          })
        })
    },
    searchReset() {
      this.query = {}
      this.treeDeptId = ""
      this.onLoad(this.page)
    },
    searchChange(params, done) {
      this.query = params
      this.page.currentPage = 1
      this.onLoad(this.page, params)
      done()
    },
    selectionChange(list) {
      this.selectionList = list
    },
    selectionClear() {
      this.selectionList = []
      this.$refs.crud.toggleSelection()
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据")
        return
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: "success",
            message: "操作成功!",
          })
          this.$refs.crud.toggleSelection()
        })
    },
    handleReset() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据")
        return
      }
      this.$confirm(`确定将选择账号密码重置为【${this.initPassword}】吗?`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return resetPassword(this.ids)
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功!",
          })
          this.$refs.crud.toggleSelection()
        })
    },
    openDoorsDis() {
      if (this.treeDeptId == "") {
        this.$message.warning("请先选择组织机构再进行操作")
        return
      }
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一位用户")
        return
      }

      this.hk_qgk_doors_ids = []
      this.hk_qgk_doorsDis = true
    },
    excelFaceBoxDia() {
      if (this.treeDeptId == "") {
        this.$message.success("请先选择组织机构再进行操作")
        return
      } else {
        this.excelFaceBox = true
      }
    },
    getDictionary() {
      getDictionary({ code: "default_hk_qgk_doors" }).then((res) => {
        this.hk_qgk_doors = res.data.data
        if (this.userInfo.tenant_id == "0000012") {
          this.hk_qgk_doors = this.hk_qgk_doors.filter((item) => item.dictValue == "5")
        }
        this.excelFaceOption.column[0].dicData = this.hk_qgk_doors
      })
    },
    addQGKHKUserAndAuth() {
      console.log(this.selectionList)
      let accountList = []
      this.selectionList.forEach((e) => {
        accountList.push(e.account)
      })
      let treeDeptId = ""
      if (this.treeDepObj.parentId == "0") {
        treeDeptId = this.treeDepObj.id
      } else {
        treeDeptId = this.treeDepObj.parentId
      }
      let obj = {
        doorsDictValue: this.hk_qgk_doors_ids,
        deptId: treeDeptId,
        accountList: accountList.toString(),
      }
      // treeDeptId
      //     accountList 待开通的账号列表（多个逗号隔开）
      // deptId 当前青高科的组织机构id
      // doorsDictValue 门禁资源组 （需要查询数据字典code=default_hk_qgk_doors）的列表，支持用户选其中一个进行开通。
      addQGKHKUserAndAuth(obj).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("开通成功")
        }
        this.hk_qgk_doorsDis = false
      })
    },
    handleGrant() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据")
        return
      }
      this.roleTreeObj = []
      if (this.selectionList.length === 1) {
        this.roleTreeObj = this.selectionList[0].roleId.split(",")
      }
      getRoleTree().then((res) => {
        this.roleGrantList = res.data.data
        this.roleBox = true
      })
    },
    handlePlatform() {
      this.platformBox = true
    },
    handleImport() {
      this.excelBox = true
    },
    uploadAfter(res, done, loading, column) {
      window.console.log(column)
      this.excelBox = false
      this.refreshChange()
      done()
    },
    handleExport() {
      this.$confirm("是否导出用户数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // exportUserData({
        //   realName: this.search.realName,
        //   account: this.search.account
        // }).then(
        //   res => {
        //     let url = window.URL.createObjectURL(new Blob([res.data]));
        //     let link = document.createElement("a");
        //     link.style.display = "none";
        //     link.href = url;
        //     link.download = "用户数据" + ".xls";
        //     document.body.appendChild(link);
        //     link.click();
        //   }
        // )
        downFileByResult(
          "/api/jida-user/export-user",
          "get",
          {
            account: this.search.account,
            realName: this.search.realName,
            deptId: this.treeDeptId,
          },
          "用户.xls"
        )
        // window.open(
        //   `/api/jida-user/export-user?${
        //     this.website.tokenHeader
        //   }=${getToken()}&account=${this.search.account}&realName=${
        //     this.search.realName
        //   }`
        // );
      })
    },
    handleTemplate() {
      downFileByResult("/api/jida-user/export-template", "get", "用户导入模板.xls")
    },
    beforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getUser(this.form.id).then((res) => {
          this.form = res.data.data
          if (this.form.hasOwnProperty("deptId")) {
            this.form.deptId = this.form.deptId.split(",")
          }
          if (this.form.hasOwnProperty("roleId")) {
            this.form.roleId = this.form.roleId.split(",")
          }
          if (this.form.hasOwnProperty("postId")) {
            this.form.postId = this.form.postId.split(",")
          }
        })
      }
      this.initFlag = true
      done()
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize
    },
    refreshChange() {
      this.onLoad(this.page, this.query)
    },
    onLoad(page, params = {}) {
      this.loading = true
      // console.log(this.userInfo);

      // if (this.treeDeptId == '') {
      //     if (this.userInfo.dept_id) {
      //         let dept_id = '';
      //         if (this.userInfo.dept_id.split(',').length > 1) {
      //             let deptIds = this.userInfo.dept_id.split(',');
      //             dept_id = deptIds[0];
      //         } else {
      //             dept_id = this.userInfo.dept_id;
      //         }
      //         console.log(dept_id);
      //         this.treeDeptId = dept_id;
      //     }
      // }
      getList(page.currentPage, page.pageSize, Object.assign(params, this.query), this.treeDeptId).then((res) => {
        const data = res.data.data
        data.records.forEach((e) => {
          if (e.faceOcrStatus) {
            e.faceOcrStatus = e.faceOcrStatus + ""
          }
          if (e.faceOcrStatus == 0) {
            e.faceOcrStatus = "0"
          }
        })

        this.page.total = data.total
        this.data = data.records
        this.loading = false
        this.selectionClear()
      })
    },
    platformRowUpdate(row, index, done, loading) {
      updatePlatform(row.id, row.userType, row.userExt).then(
        () => {
          this.platformOnLoad(this.platformPage)
          this.$message({
            type: "success",
            message: "操作成功!",
          })
          done()
        },
        (error) => {
          window.console.log(error)
          loading()
        }
      )
    },
    platformBeforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getUserPlatform(this.platformForm.id).then((res) => {
          this.platformForm = res.data.data
        })
      }
      done()
    },
    platformSearchReset() {
      this.platformQuery = {}
      this.platformOnLoad(this.platformPage)
    },
    platformSearchChange(params, done) {
      this.platformQuery = params
      this.platformPage.currentPage = 1
      this.platformOnLoad(this.platformPage, params)
      done()
    },
    platformSelectionChange(list) {
      this.platformSelectionList = list
    },
    platformSelectionClear() {
      this.platformSelectionList = []
      this.$refs.platformCrud.toggleSelection()
    },
    platformCurrentChange(currentPage) {
      this.platformPage.currentPage = currentPage
    },
    platformSizeChange(pageSize) {
      this.platformPage.pageSize = pageSize
    },
    platformRefreshChange() {
      this.platformOnLoad(this.platformPage, this.platformQuery)
    },
    platformOnLoad(page, params = {}) {
      this.platformLoading = true
      getList(page.currentPage, page.pageSize, Object.assign(params, this.query), this.treeDeptId).then((res) => {
        const data = res.data.data
        this.platformPage.total = data.total
        this.platformData = data.records
        this.platformLoading = false
        this.selectionClear()
      })
    },
  },
}
</script>

<style>
.box {
  height: 800px;
}

.el-scrollbar {
  height: 100%;
}

.box .el-scrollbar__wrap {
  overflow: scroll;
}
</style>
