<template>
  <el-form
    v-loading="loading"
    ref="form"
    :model="form"
    :rules="rules"
    size="small"
    label-width="100px"
    label-position="top"
  >
    <el-row>
      <el-col :span="12">
        <el-form-item label="协议类型" prop="supplementary_type">
          <el-select class="dis-b" v-model="form.supplementary_type" disabled>
            <el-option
              v-for="item of supplementaryType"
              :key="item.id"
              :label="item.dictValue"
              :value="item.dictKey"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="协议编号" prop="supplementary_code">
          <el-input :disabled="disabled" v-model="form.supplementary_code"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="协议模板" prop="supplementary_template_id">
          <model-search-select
            class="dis-b"
            :disabled="disabled"
            v-model="form.supplementary_template_id"
            model="wp_contract_template"
            labelKey="template_name"
            valueKey="id"
            :params="{ template_type: '2' }"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <!-- <el-col :span="12">
      <el-form-item label="约定开始时间" prop="start_date">
        <el-date-picker
          :disabled="disabled"
          class="dis-b w-100p"
          v-model="form.start_date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd 00:00:00"
        >
        </el-date-picker>
      </el-form-item>
    </el-col> -->
      <el-col :span="12">
        <el-form-item label="提前终止时间" prop="end_date">
          <el-date-picker
            @change="calculate()"
            :disabled="disabled"
            class="dis-b w-100p"
            v-model="form.end_date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd 23:59:59"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="签订时间" prop="sign_date">
          <el-date-picker
            :disabled="disabled"
            class="dis-b w-100p"
            v-model="form.sign_date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="甲方签订人" prop="sign_person">
          <el-input :disabled="disabled" v-model="form.sign_person"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="乙方公司" prop="lessee_a_company_id">
          <model-search-select
            :disabled="disabled"
            class="dis-b w-100p"
            v-model="form.lessee_a_company_id"
            model="wp_company"
            :fillModel.sync="form"
            :fills="[{ source: 'name', target: 'lessee_a_name' }]"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="乙方签订人" prop="lessee_a_sign">
          <el-input :disabled="disabled" v-model="form.lessee_a_sign"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="备注123" prop="note">
          <el-input :disabled="disabled" v-model="form.note" type="textarea"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-divider content-position="left"><span class="is-required-text">租赁位置</span></el-divider>
      </el-col>
      <el-col :span="24">
        <!-- <div v-if="roomSelectedError" style="position: static" class="el-form-item__error">请输入房间面积、单价和物业管理费</div> -->
        <el-table :data="form.subjects" size="small" border>
          <!-- <el-table-column
          type="index"
          v-if="!disabled"
          width="50">
          <template slot="header">
            <el-button size="mini" @click="addRoom()" type="primary" icon="el-icon-plus" circle></el-button>
          </template>
          <template slot-scope="scope">
            <el-button size="mini" @click="form.subjects.splice(scope.$index , 1);calculate();" type="danger" icon="el-icon-minus" circle></el-button>
          </template>
        </el-table-column> -->
          <el-table-column label="是否释放房间" width="110px">
            <span slot="header" class="is-required-text">操作</span>
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="danger"
                @click="released(scope.row.id, '1')"
                v-if="scope.row.is_released == '0'"
              >
                解除占用
              </el-button>
              <el-button size="mini" type="success" @click="released(scope.row.id, '0')" v-else>占用房间</el-button>
            </template>
          </el-table-column>
          <el-table-column label="房间位置" width="400px" prop="location_name">
            <!-- <template slot-scope="scope"> -->
            <!-- <LocationCascader :disabledList="form.subjects.map(item => item.room_id)" size="small" :props="{checkStrictly: false}" :disabled="disabled" v-model="scope.row.locations" @change="(data) => cascaderChange(data , scope.row)"></LocationCascader> -->
            <!-- </template> -->
          </el-table-column>
          <!-- <el-table-column label="楼层" width="50px" prop="floor_name"></el-table-column>
        <el-table-column label="房间" width="100px" prop="room_name"></el-table-column> -->
          <el-table-column label="房间面积" width="200px">
            <!-- <span slot="header" class="is-required-text">房间面积</span> -->
            <template slot-scope="scope">
              <!-- <el-input size="small" type="number" :disabled="disabled" placeholder="请输入" @change="calculate();" v-model="scope.row.room_area">
              <template slot="append">㎡</template>
            </el-input> -->
              <span>{{ scope.row.room_area }}m²</span>
            </template>
          </el-table-column>
          <el-table-column label="单价" width="200px">
            <!-- <span slot="header" class="is-required-text">单价</span> -->
            <template slot-scope="scope">
              <!-- <el-input size="small" type="number" :disabled="disabled" placeholder="请输入" @change="calculate();" v-model="scope.row.rental_unit_price_day">
              <template slot="append">元/㎡·日</template>
            </el-input> -->
              <span>{{ scope.row.rental_unit_price_day }}元/㎡·日</span>
            </template>
          </el-table-column>
          <el-table-column label="物业管理费" width="200px">
            <!-- <span slot="header" class="is-required-text">物业管理费</span> -->
            <template slot-scope="scope">
              <!-- <el-input size="small" type="number" :disabled="disabled" placeholder="请输入" @change="calculate();" v-model="scope.row.property_management_fee">
              <template slot="append">元/㎡·月</template>
            </el-input> -->
              <span>{{ scope.row.property_management_fee }}元/㎡·月</span>
            </template>
          </el-table-column>
          <el-table-column width="160px" label="租赁开始时间">
            <!-- <span slot="header" class="is-required-text">租赁开始时间</span> -->
            <template slot-scope="scope">
              <span>{{ scope.row.start_date }}</span>
              <!-- <el-date-picker :disabled="disabled" :picker-options="{disabledDate: (time) => {
              return roomDatePickerPropsDisabledDate('start' , scope.row , time);
            }}" size="small" class="w-100p" value-format="yyyy-MM-dd" @change="calculate();" v-model="scope.row.start_date" type="date" placeholder="年 / 月 / 日"></el-date-picker> -->
            </template>
          </el-table-column>
          <el-table-column width="160px" label="租赁结束时间">
            <!-- <span slot="header" class="is-required-text">租赁结束时间</span> -->
            <template slot-scope="scope">
              <span>{{ scope.row.end_date }}</span>
              <!-- <el-date-picker :disabled="disabled" :picker-options="{disabledDate: (time) => {
              return roomDatePickerPropsDisabledDate('end' , scope.row , time);
            }}" size="small" class="w-100p" value-format="yyyy-MM-dd" @change="calculate();" v-model="scope.row.end_date" type="date" placeholder="年 / 月 / 日"></el-date-picker> -->
            </template>
          </el-table-column>
          <!-- <el-table-column label="租金" width="150px" prop="amount">
          <template slot-scope="scope">
            <span>￥{{scope.row.amount}}</span>
          </template>
        </el-table-column> -->
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="附件" prop="attachment_ids">
          <ManageFormFile :disabled="disabled" v-model="form.attachment_ids"></ManageFormFile>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col class="mar-tb-10" :span="24">
        <el-divider content-position="left">收款计划</el-divider>
      </el-col>
      <el-col :span="24">
        <el-form-item label-width="0px" prop="collection_plan_strategy">
          <el-radio-group
            :disabled="disabled"
            v-model="form.collection_plan_strategy"
            @change="form.collection_plan_strategy == 1 ? calculate() : clearFeeData()"
          >
            <el-radio label="1">自动</el-radio>
            <el-radio label="2">手动填写</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-for="(name, index) of ['rent']">
      <!-- <el-col class="mar-tb-10" :span="24" v-if="form.property_fee_way == 1 ? name == 'rent' : true">
      <el-divider content-position="left">{{name === 'deposit' ? '保证金' : '租金'}}</el-divider>
    </el-col> -->
      <el-col :span="24" v-if="form.property_fee_way == 1 ? name == 'rent' : true">
        <div class="pad-b-15" v-if="!disabled">
          <el-button
            v-if="form.collection_plan_strategy == 2"
            @click="addFeeData(name)"
            type="primary"
            size="small"
            icon="el-icon-plus"
            >添加</el-button
          >
        </div>
        <el-table :data="form.collectionPlans[name] || []" size="small" border>
          <el-table-column v-if="!disabled && form.collection_plan_strategy == 2" width="50px">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="removeFeeDataItem(scope.$index, name)"
                icon="el-icon-minus"
                circle
              ></el-button>
            </template>
          </el-table-column>
          <el-table-column label="期数" width="80px" prop="name"></el-table-column>
          <el-table-column label="应收日期" width="150px" prop="receivableDate">
            <template slot-scope="scope">
              <span v-if="form.collection_plan_strategy == 1">{{ scope.row.receivableDate }}</span>
              <el-date-picker
                v-if="form.collection_plan_strategy == 2"
                size="small"
                class="w-100p"
                value-format="yyyy-MM-dd"
                v-model="scope.row.receivableDate"
                type="date"
                placeholder="年 / 月 / 日"
              ></el-date-picker>
            </template>
          </el-table-column>
          <template v-if="name === 'rent'">
            <el-table-column label="账单周期" width="375px">
              <template slot-scope="scope">
                <span v-if="form.collection_plan_strategy == 1">
                  {{ scope.row.startTime | formatDate("YYYY-MM-DD") }} 至
                  {{ scope.row.endTime | formatDate("YYYY-MM-DD") }}
                </span>
                <template v-if="form.collection_plan_strategy == 2">
                  <el-date-picker
                    :disabled="disabled"
                    size="small"
                    v-model="scope.row.times"
                    @change="(ev) => feeDataTableTimeChange(ev, scope.row)"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    :unlink-panels="true"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </template>
              </template>
            </el-table-column>
            <el-table-column v-if="form.property_fee_way == 2" label="物业费(元)" prop="propertyManagementFeeTotal">
              <template slot-scope="scope">
                <span v-if="!editMoney && form.collection_plan_strategy == 1">{{
                  scope.row.propertyManagementFeeTotal
                }}</span>
                <el-input
                  :disabled="disabled"
                  size="small"
                  v-if="editMoney || form.collection_plan_strategy == 2"
                  type="number"
                  v-model="scope.row.propertyManagementFeeTotal"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="租金(元)" prop="amountReceivable">
              <template slot-scope="scope">
                <span v-if="!editMoney && form.collection_plan_strategy == 1">{{ scope.row.amountReceivable }}</span>
                <el-input
                  :disabled="disabled"
                  size="small"
                  v-if="editMoney || form.collection_plan_strategy == 2"
                  type="number"
                  v-model="scope.row.amountReceivable"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="本期应收金额(元)" header-align="right" align="right">
              <template slot-scope="scope">
                ￥<span class="text-red">{{ getAllMoney(scope.row) }}</span>
              </template>
            </el-table-column>
          </template>
          <template v-if="name === 'deposit'">
            <el-table-column label="本期应收金额(元)" header-align="right" align="right" prop="amountReceivable">
              <template slot-scope="scope">
                <span v-if="!editMoney">{{ scope.row.amountReceivable }}</span>
                <el-input
                  :disabled="disabled"
                  class="w-200"
                  size="small"
                  v-if="editMoney"
                  type="number"
                  v-model="scope.row.amountReceivable"
                ></el-input>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <div class="text-right fts-18 pad-10 bor bor-t-n">
          应收总额 ￥{{ form.collectionPlans[name] ? getAllMoney(form.collectionPlans[name], true) : 0 }}元
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import ManageFormFile from "../manageFormFile.vue"
import {
  getSupplementaryDetail,
  supplementaryCalculate,
  querySupplementaryCollectionPlan,
} from "@/api/contract/index.js"
import { updateMetaCommon, getMetaCommonDetail } from "@/api/busiMode/metaCommon"
export default {
  components: { ManageFormFile },
  inject: ["supplementarysIndex"],
  data() {
    return {
      loading: false,
      form: {
        amount: null,
        contract_id: "",
        contract_code: "",
        supplementary_code: "",
        supplementary_type: "",
        supplementary_template_id: "",
        start_date: "",
        end_date: "",
        sign_date: "",
        sign_person: "",
        lessee_a_name: "",
        lessee_a_company_id: "",
        lessee_a_sign: "",
        lessee_b_name: "",
        lessee_b_company_id: "",
        lessee_b_sign: "",
        note: "",
        attachment_ids: "",
        frequency_unit: "",
        frequency: "",
        year_increase: null,
        increase_rate: null,
        receipt_strategy: "1",
        subjects: [],
        contractReductionPolicies: [],
        collectionPlans: {
          deposit: [],
          rent: [],
        },
        preferential: "1",
        collection_plan_strategy: "1",
        property_fee_way: null,
        payment_before_day: null,
        attachment_ids: "",
        start_fix_date: "",
        end_fix_date: "",
        fix_rent_free: "2",
      },
      rules: {
        supplementary_code: [{ required: true, trigger: "blur", message: "请输入 协议编号" }],
        // supplementary_template_id: [ {required: true , trigger: "blur" , message: '请选择 协议模板'} ],
        supplementary_type: [{ required: true, trigger: "blur", message: "请选择 协议类型" }],
        lessee_a_company_id: [{ required: true, trigger: "blur", message: "请选择 乙方公司" }],
        sign_person: [{ required: true, trigger: "blur", message: "请输入 甲方签订人" }],
        sign_date: [{ required: true, trigger: "blur", message: "请选择 签订时间" }],
        lessee_a_sign: [{ required: true, trigger: "blur", message: "请输入 乙方签订人" }],
        lessee_b_sign: [{ required: true, trigger: "blur", message: "请输入 丙方签订人" }],
      },
      model: "wisdom_park.wp_contract_supplementary",
    }
  },
  computed: {
    disabled() {
      return this.mode === "view" ? true : false
    },
  },
  props: {
    supplementaryType: {
      type: Array,
      default() {
        return []
      },
    },
    contractInfo: {
      type: Object,
      default() {
        return null
      },
    },
    type: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "",
    },
    editMoney: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.form["supplementary_type"] = this.type
    if (this.id) {
      // this.getMetaCommonDetail();
      this.getDetail()
    } else {
      this.supplementarysIndex.initForm(this.form)
      this.form.contractReductionPolicies = this.contractInfo.contractReductionPolicies || []
      this.form.collectionPlans = this.contractInfo.collectionPlans || {
        deposit: [],
        rent: [],
      }
      this.calculate()
      // this.form['lessee_a_company_id'] = this.contractInfo.companyId;
      // this.form['lessee_a_name'] = this.contractInfo.rentersName;
      // this.form['start_date'] = this.contractInfo.startDate;
      // this.form['end_date'] = this.contractInfo.endDate;
      // this.form['contract_code'] = this.contractInfo.code;
      // this.form['contract_id'] = this.contractInfo.id;
    }
  },
  methods: {
    /**
     * 更新合同与房间的占用关系
     * @param {主键ID} id
     * @param {最终的房间状态} is_released
     */
    released(id, is_released) {
      let r = { id, is_released }

      let o = {
        update: JSON.parse(JSON.stringify(r)),
        primaryKeyValue: id,
        primaryKey: "id",
      }
      updateMetaCommon("wisdom_park.wp_contract_subject", o).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: is_released === "0" ? "房间已占用" : "已解除房间的占用状态",
          })
          this.getDetail()
        }
      })
    },

    getAllMoney(data, isList) {
      if (isList) {
        let amount = 0
        data.forEach((item) => {
          amount = mathjs
            .chain(amount)
            .add(item.propertyManagementFeeTotal || 0)
            .add(item.amountReceivable || 0)
            .done()
        })
        return amount
      } else {
        return mathjs.add(data.propertyManagementFeeTotal || 0, data.amountReceivable || 0)
      }
    },
    getMetaCommonDetail() {
      this.loading = true
      getMetaCommonDetail(this.model, {
        pk: "id",
        id: this.id,
      }).then((res) => {
        this.loading = false
        let form = res.data.data
        Object.keys(form).forEach((keyName) => {
          if (typeof form[keyName] === "number") {
            form[keyName] = `${form[keyName]}`
          }
        })
        this.form = form
      })
    },
    getDetail() {
      this.loading = true
      getSupplementaryDetail({
        id: this.id,
      }).then((res) => {
        this.loading = false
        let form = {},
          data = res.data.data
        let excludes = ["contractReductionPolicies", "collectionPlans", "subjects"]
        Object.keys(data).forEach((keyName) => {
          if (!excludes.includes(keyName)) {
            let name = keyName.replace(/([A-Z])/g, "_$1").toLowerCase()
            if (typeof data[keyName] === "number") {
              form[name] = `${data[keyName]}`
            } else {
              form[name] = data[keyName]
            }
          } else {
            form[keyName] = data[keyName]
          }
        })
        form.collectionPlans = data.collectionPlans || {
          deposit: [],
          rent: [],
        }
        // form["subjects"] = (this.supplementarysIndex.contractInfo.contractSubjects || []).map(
        //   (item) => {
        //     let newItem = {
        //       locations: [item.buildingId, item.floorId, item.roomId],
        //       type: 2,
        //       contract_id: this.contractInfo.id,
        //       location_name: `${item.buildingName}${item.floorName}${item.roomName}`
        //     };
        //     Object.keys(item).forEach((keyName) => {
        //       let name = keyName.replace(/([A-Z])/g, "_$1").toLowerCase();
        //       newItem[name] = item[keyName];
        //     });
        //     delete newItem["id"];
        //     return newItem;
        //   }
        // );
        form.subjects = (form.subjects || []).map((item) => {
          let newItem = {
            locations: [item.buildingId, item.floorId, item.roomId],
            type: 2,
            contract_id: this.contractInfo.id,
            location_name: `${item.buildingName}${item.floorName}${item.roomName}`,
          }
          item.isReleased = item.isReleased + ""
          Object.keys(item).forEach((keyName) => {
            let name = keyName.replace(/([A-Z])/g, "_$1").toLowerCase()
            newItem[name] = item[keyName]
          })
          return newItem
        })
        this.form = form
        querySupplementaryCollectionPlan({
          supplementaryId: this.form.id,
        }).then((res) => {
          let data = res.data.data
          let collectionPlans = {
            ...this.form.collectionPlans,
            ...data,
          }
          if (this.form.collection_plan_strategy == 2) {
            Object.keys(collectionPlans).forEach((keyName) => {
              collectionPlans[keyName].forEach((item) => {
                item.times = [item.startTime, item.endTime]
              })
            })
          }
          this.form.collectionPlans = collectionPlans
        })
      })
    },
    calculate() {
      if (this.form["collection_plan_strategy"] == 2) {
        return
      }
      let body = {
        ...this.form,
        subjects: this.form.subjects,
        contractReductionPolicies: this.form.contractReductionPolicies.map((item) => {
          let newItem = {
            ...item,
          }
          if (this.form.id) {
            newItem["contractId"] = this.form.id
          }
          return newItem
        }),
      }

      ;[
        { code: "start_date", type: 1 },
        { code: "end_date", type: 2 },
        { code: "fix_date", type: 1 },
      ].forEach((item) => {
        if (body[item.code]) {
          let val = body[item.code].split(" ")[0]
          body[item.code] = item.type === 1 ? `${val} 00:00:00` : `${val} 23:59:59`
        }
      })
      if (!this.form.start_date || !this.form.end_date) {
        this.form.collectionPlans = { deposit: [], rent: [] }
        return false
      }

      delete body.collectionPlans

      supplementaryCalculate(body).then((res) => {
        if (res.data.data) {
          this.form.collectionPlans = res.data.data.rent
            ? res.data.data
            : {
                deposit: [],
                rent: [],
              }
          this.form.amount = this.getAllMoney(this.form.collectionPlans.rent, true)
        }
      })
    },
    removeFeeDataItem(index, typeName) {
      this.form.collectionPlans[typeName].splice(index, 1)
      this.form.collectionPlans[typeName].forEach((item, index) => {
        item.name = `第${index + 1}期`
      })
    },
    feeDataTableTimeChange(ev, row) {
      if (ev && ev.length) {
        row.startTime = `${ev[0]} 00:00:00`
        row.endTime = `${ev[1]} 23:59:59`
      }
    },
    addFeeData(typeName) {
      this.form.collectionPlans[typeName] = this.form.collectionPlans[typeName] || []
      this.form.collectionPlans[typeName].push({
        name: `第${this.form.collectionPlans[typeName].length + 1}期`,
        receivableDate: "",
        startTime: "",
        endTime: "",
        amountReceivable: 0,
        collectionItem: typeName,
        days: 0,
        propertyManagementFeeTotal: 0,
        times: [],
      })
    },
    clearFeeData() {
      this.form.collectionPlans["deposit"] = []
      this.form.collectionPlans["rent"] = []
      // console.log(this.feeData);
    },
    save(callback) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          callback(this.form)
        } else {
          callback(null)
        }
      })
    },
  },
}
</script>
